<template>
  <div class="col-12 px-4">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.template") }}
              <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.inpage.template_hint'),
                  triggers: ['hover', 'click'],
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
            <custom-multiselect ref="inpageSelect" class="multiselect-wrapper multiselect-w-100" v-model="in_page_templates" :disabled="enable_split_testing" :list="templateOptions" :searchBy="'label'" :treeSelect="true" :allowEmpty="false" :multiply="true">
              <template v-slot:block="props">
                <span v-if="props.value">{{ props.value.label || props.value }}</span>
              </template>
              <template v-slot:tree-parent="props">
                <span>{{ props.value.label }}</span>
              </template>
              <template v-slot:tree-child="props">
                <span>{{ props.value.label }}</span>
              </template>
            </custom-multiselect>
            <CheckBoxWrapper class="mt-3" 
              :disabled="in_page_templates.length == 1"
              v-model="enable_split_testing" :text="$t('platform.settings.split_test_title')" />
            <div class="info-box mt-2">
              <svg v-svg symbol="info" size="0 0 18 18" role="info" />
              <span v-html="$t('platform.settings.split_testing')"></span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="delay" :max="60" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.delay'" :description="'platform.settings.description.inpage.delay'" />
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.position") }}

              <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.description.inpage.position'),
                  triggers: ['hover', 'click'],
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
            <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="position" :list="positionOptions" :allowEmpty="false">
              <template v-slot:block="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
            </custom-multiselect>
          </div>

          <div class="col-12 col-lg-6 space-for-sm">
            <Counter v-model="delay_after_click" :max="600" :min="0" :placeholder="$t('sec') + '.'" :title="'platform.settings.inpage.pause_after_click'" :description="'platform.settings.description.inpage.pause_after_click'" />
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="delay_after_close" :max="600" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.pause_after_close'" :description="'platform.settings.description.inpage.pause_after_close'" />
          </div>

          <div class="col-12 col-lg-6 space-for-sm">
            <Counter v-model="close_button_delay" :max="30" :min="0" :placeholder="$t('sec') + '.'" :title="'platform.settings.inpage.close_delay'" :description="'platform.settings.inpage.close_delay_hint'" />
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="clicks_to_close_count" :max="5" :min="1" :title="'platform.settings.inpage.close_clicks_amount'" :description="'platform.settings.inpage.close_clicks_hint'" />
          </div>
          <div class="col-12 col-lg-6" v-if="featureFlags.includes('auto_refresh_interval')">
            <Counter v-model="auto_refresh_interval" :placeholder="$t('sec') + '.'" :max="120" :min="10" :title="'platform.settings.inpage.autorefresh_interval'" :description="'platform.settings.inpage.autorefresh_hint'" />
          </div>
          <div class="col-12">
            <CheckBoxWrapper class="mt-3" v-model="show_container" :text="$t('platform.settings.inpage.show_container')" />
            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.inpage.show_container_hint'),
                triggers: ['hover', 'click'],
              }"
              style="fill: #5183cf"
              symbol="info"
              size="0 0 18 18"
              class="mb-2 ml-1"
            />
            <div class="form-item__field mt-2" :class="{ 'mb-0': $v.selector.valid }" v-if="show_container">
              <label for="selector" class="form-item__label">{{ $t("platform.settings.inpage.selector") }}</label>
              <input type="text" name="selector" :class="{ invalid: !$v.selector.valid && $v.selector.$dirty }" v-model.trim="selector" @blur="$v.selector.$touch" />
              <small v-if="!$v.selector.valid && $v.selector.$dirty" class="error mt-2">
                {{ $t("platform.settings.pop.invalid_selector") }}
              </small>
            </div>
          </div>
          <div class="col-12 mb-4">
            <CheckBoxWrapper class="mt-3" v-model="show_ad" :text="$t('platform.settings.show_ad_preview')" />
          </div>
          <div class="col-12">
            <CheckBoxWrapper v-model="enable_onclick_backdrop" :text="$t('platform.settings.enable_onclick_blackout')" />
            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.description.inpage.onclick_blackout'),
                triggers: ['hover', 'click'],
              }"
              style="fill: #5183cf"
              symbol="info"
              size="0 0 18 18"
              class="mb-2 ml-1"
            />
          </div>
          <div class="col-12 mt-4">
            <DelaySettings :delay="dimming" v-model="dimming" :min="0" :max="100" :variable="'%'" :title="'platform.settings.blackout_range'" :description="'platform.settings.description.native_web_push.blackout_settings'" class="pb-2" />
          </div>
          <template v-if="featureFlags.includes('in_page_missclick')">
            <div class="col-12">
              <label class="form-item__label info-popup">
                {{ $t("platform.settings.inpage.sell_missclick_as") }}
              </label>
              <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="sell_missclick_as" :list="miss_click_options" :allowEmpty="false">
                <template v-slot:block="props">
                  <span>
                    {{ props.value.value }}
                  </span>
                </template>
                <template v-slot:list="props">
                  <span>
                    {{ props.value.value }}
                  </span>
                </template>
              </custom-multiselect>

              <template v-if="sell_missclick_as.value === 'OnClick'">
                <label class="form-item__label info-popup mt-3">
                  {{ $t("platform.settings.pop.pop_type") }}
                  <svg
                    v-svg
                    v-tooltip="{
                      content: $t('platform.settings.description.onclick.pop_type'),
                      triggers: ['hover', 'click'],
                    }"
                    symbol="info"
                    size="0 0 18 18"
                    role="info"
                  />
                </label>
                <custom-multiselect v-model="open_type" :list="open_type_options" class="multiselect-wrapper form-item__field" :allowEmpty="false">
                  <template v-slot:block="props">
                    <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
                  </template>
                  <template v-slot:list="props">
                    <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
                  </template>
                </custom-multiselect>
              </template>
            </div>
          </template>

          <div class="col-12" v-if="featureFlags.includes('enable-display-settings')">
            <DisplaySettings :displaySettings="displaySettings" :disableOsVersions="osVersions" />
          </div>
        </div>

        <button @click.prevent="sendRequest" class="btn my-3 _mob100">
          {{ $t("save") }}
        </button>
        <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green my-3 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="coolicon"></svg> {{ $t("platforms.table.get_code") }}</button>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="banner-wrapper">
          <img v-if="loading" width="64px" height="64px" :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
          <img v-else-if="!loading && isImage" :src="require(`@/assets/images/previews/${previewImage}`)" class="preview" :class="[position.value, lastPreview.label.toLowerCase() + '-temp']" />
          <LottieAnimation v-else-if="!loading && !isImage" ref="anim" :loop="true" :auto-play="true" :animationData="require(`@/assets/images/previews/${previewImage}`)" class="preview" :class="[position.value, lastPreview.label.toLowerCase() + '-temp']" />
          <div class="screen__opacity" :style="`opacity: ${dimming / 100}`"></div>
          <img src="@/assets/images/in-page-banner.svg" class="banner" />
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import Counter from "../Counter.vue";
import CheckBoxWrapper from "@/components/CheckBoxWrapper.vue";
import DelaySettings from "../DelaySettings.vue";
import DisplaySettings from "../DisplaySettings.vue";
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";
import isValidSelector from "@/mixins/isValidSelector";
import { validationMixin } from "vuelidate";

export default {
  name: "InPage",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    CheckBoxWrapper,
    Counter,
    DelaySettings,
    DisplaySettings,
    LottieAnimation,
  },
  mixins: [platformSettingsGetCode, isValidSelector, validationMixin],
  watch: {
    enable_split_testing(val) {
      if (val) this.$refs["inpageSelect"].$el.classList.add("disabled");
      else this.$refs["inpageSelect"].$el.classList.remove("disabled");
    },
    previewImage: {
      handler() {
        this.loading = true;

        setTimeout(() => {
          this.loading = false;
        }, 0);
      },
    },
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
    lastPreview() {
      return this.in_page_templates.at(-1);
    },
    isImage() {
      return !this.previewImage.endsWith(".json");
    },
    previewImage() {
      const adType = this.show_ad ? "ad" : "noAd";
      const animation = ["Twist Notification", "Spin Animation", "Ripple effect", "Ripple effect 2", "Purple gradient", "Pure white", "Float Motion"];
      const result = animation.includes(this.lastPreview.label) ? `animate/${this.imageMap[this.lastPreview.label]}.json` : `${this.imageMap[this.lastPreview.label]}.svg`;
      return `${adType}/${result}`;
    },
    imageMap() {
      return {
        Default: "Main",
        Mac: "Mac",
        Windows: "Windows",
        Android: "Android",
        iOS: "iOS",
        Black: "Black",
        White: "White",
        "Pink(Dating)": "Pink_Dating",
        "White(Online)": "White_Online",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Telegram: "Telegram",
        Twitter: "Twitter",
        Whatsapp: "Whatsapp",
        Small: "Small",
        Dialog: "Dialog",
        Compact: "Compact",
        "Static display": "Static_display",
        Light: "Light",
        Dark: "Dark",
        "IOS Dark": "iOS_Dark",
        Large: "Large",
        "Large 1": "Large_1",
        "Large 2": "Large_2",
        "Large 3": "Large_3",
        Deep: "Deep",
        Frame: "Frame",
        Grand: "Grand",
        "Twist Notification": "Twist_Notification",
        "Spin Animation": "Spin_animation",
        "Ripple effect": "Ripple_effect",
        "Ripple effect 2": "Ripple_effect_2",
        "Purple gradient": "Purple_gradient",
        "Pure white": "Pure_white",
        "Float Motion": "Float_Motion",
      };
    },
  },
  validations: {
    selector: {
      valid: function (value) {
        return this.isValidSelector(value) || !this.show_container;
      },
    },
  },
  data() {
    return {
      enable_split_testing: false,
      show_container: false,
      enable_onclick_backdrop: false,
      selector: "",
      loading: true,
      delay: 0,
      show_ad: false,
      dimming: 0,
      close_button_delay: 0,
      clicks_to_close_count: 1,
      auto_refresh_interval: 30,
      position: { id: 4, value: "bottom-right" },
      templateOptions: [
        {
          id: 1,
          label: "Default",
        },
        {
          id: 100,
          label: "InPage Push",
          disable: true,
          children: [
            { id: -1, label: "OS", groupValue: true },
            { id: 101, label: "Mac" },
            { id: 102, label: "Windows" },
            { id: 103, label: "Android" },
            { id: 104, label: "iOS" },
            { id: -1, label: "Custom", groupValue: true },
            { id: 105, label: "Black" },
            { id: 106, label: "White" },
            { id: 107, label: "Pink(Dating)" },
            { id: 108, label: "White(Online)" },
            { id: -1, label: "Social", groupValue: true },
            { id: 109, label: "Facebook" },
            { id: 110, label: "Instagram" },
            { id: 111, label: "Telegram" },
            { id: 112, label: "Twitter" },
            { id: 113, label: "Whatsapp" },
            { id: -1, label: "Classic", groupValue: true },
            { id: 114, label: "Small" },
            { id: 115, label: "Dialog" },
            { id: 116, label: "Compact" },
            { id: 117, label: "Frame" },
          ],
        },
        {
          id: 200,
          label: "Alert Icons",
          disable: true,
          children: [
            { id: -1, label: "Basic bubble", groupValue: true },
            { id: 201, label: "Ripple effect" },
            { id: 202, label: "Ripple effect 2" },
            { id: 203, label: "Static display" },
            { id: -1, label: "Text Pulse", groupValue: true },
            { id: 204, label: "Spin Animation" },
            { id: 205, label: "Twist Notification" },
            { id: -1, label: "Dynamic Message", groupValue: true },
            { id: 206, label: "Pure white" },
            { id: 207, label: "Float Motion" },
            { id: 208, label: "Purple gradient" },
          ],
        },
        {
          id: 300,
          label: "Custom widgets",
          disable: true,
          children: [
            { id: -1, label: "Flat style", groupValue: true },
            { id: 301, label: "Light" },
            { id: 302, label: "Dark" },
            { id: -1, label: "Retro", groupValue: true },
            { id: 303, label: "IOS Dark" },
            { id: 304, label: "Large" },
            { id: 305, label: "Large 1" },
            { id: 306, label: "Large 2" },
            { id: 307, label: "Large 3" },
            { id: 308, label: "Deep" },
            { id: 309, label: "Grand" },
          ],
        },
        { label: this.$formatLabel("select_all"), selectAllOption: true },
      ],
      in_page_templates: [{ id: 0, label: "Default" }],
      delay_after_click: 10,
      delay_after_close: 10,
      positionOptions: [
        { id: 1, value: "top-left" },
        { id: 2, value: "top-right" },
        { id: 3, value: "bottom-left" },
        { id: 4, value: "bottom-right" },
        { id: 5, value: "center" },
      ],
      sell_missclick_as: { value: "OnClick" },
      miss_click_options: [{ value: "OnClick" }, { value: "InPage" }],
      open_type: { id: 1, title: "new_tab" },
      open_type_options: [
        { id: 1, title: "new_tab" },
        { id: 2, title: "new_tab_left" },
        { id: 4, title: "replace" },
        { id: 5, title: "popup" },
      ],
      displaySettings: {
        disableDeviceSell: [],
        disableOsSell: [],
        notShowReff: false,
        disableYandex: false,
      },
      osVersions: [],
    };
  },

  mounted() {
    const id = this.$route.params.id;

    siteSettingsService
      .getOsVersions()
      .then((res) => {
        if (res && res.status === 200) {
          this.osVersions = res.data;
          return siteSettingsService.getInPage(id);
        }
      })
      .then((resp) => {
        if (resp.data.length === 0) {
          console.log("No data");
        } else {
          this.enable_split_testing = resp.data.enable_split_testing;
          this.show_ad = resp.data.show_ad || false;
          this.delay = resp.data.delay;
          this.position.value = resp.data.position;
          this.delay_after_click = resp.data.delay_after_click;
          this.delay_after_close = resp.data.delay_after_close;
          this.auto_refresh_interval = resp.data.auto_refresh_interval || 30;
          this.open_type = this.setPopType(resp.data.missclick?.open_type);
          this.sell_missclick_as.value = resp.data.missclick?.sell_as || "OnClick";

          this.in_page_templates = this.findOptionsByLabels(this.templateOptions, resp.data.in_page_templates) || [{ id: 0, label: "Default" }];

          this.show_container = resp.data.enable_selector;
          this.selector = resp.data.selector || "";
          this.enable_onclick_backdrop = resp.data.enable_onclick_backdrop;
          this.dimming = resp.data.dimming;
          this.close_button_delay = resp.data.close_button_delay;
          this.clicks_to_close_count = resp.data.clicks_to_close_count || 1;

          this.displaySettings = {
            disableDeviceSell: resp.data.display_settings?.disable_devices || [],
            disableOsSell: this.osVersions.filter((el) => resp.data.display_settings?.disable_os.includes(el.value)) || [],

            notShowReff: resp.data.display_settings?.disable_no_referer || false,
            disableYandex: resp.data.display_settings?.disable_yandex_browser || false,
          };
        }
        this.loading = false;
      });
  },
  methods: {
    setPopType(type) {
      const defaultOption = { id: 1, title: "new_tab" };
      const selectedOption = this.open_type_options.find((option) => option.title === type);
      return selectedOption || defaultOption;
    },
    sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const id = this.$route.params.id;
      const payload = {
        enable_split_testing: this.enable_split_testing,
        show_ad: this.show_ad,
        delay: this.delay,
        enable_selector: this.show_container,
        selector: this.selector,
        position: this.position.value,
        delay_after_click: this.delay_after_click,
        delay_after_close: this.delay_after_close,
        auto_refresh_interval: this.featureFlags.includes("auto_refresh_interval") ? this.auto_refresh_interval : undefined,
        missclick: this.featureFlags.includes("in_page_missclick")
          ? {
              sell_as: this.sell_missclick_as.value,
              open_type: this.sell_missclick_as.value === "OnClick" ? this.open_type.title : undefined,
            }
          : undefined,

        in_page_templates: this.in_page_templates.map((el) => el.label),
        dimming: this.dimming,
        enable_onclick_backdrop: this.enable_onclick_backdrop,
        close_button_delay: this.close_button_delay,
        clicks_to_close_count: this.clicks_to_close_count,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell || [],
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,
      };

      siteSettingsService.setInPage(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 500) {
          this.$alert({
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
    findOptionsByLabels(options, labels) {
      const results = [];
      const findOptionByLabel = (options, label) => {
        for (const option of options) {
          if (option.label === label) {
            return { id: option.id, label: option.label };
          }
          if (option.children) {
            const found = findOptionByLabel(option.children, label);
            if (found) {
              return found;
            }
          }
        }
        return undefined;
      };

      for (const label of labels) {
        const result = findOptionByLabel(options, label);
        if (result) {
          results.push(result);
        }
      }
      return results.length ? results : undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.info-box {
  border: 1px solid #eeeef3;
  border-radius: 10px;
  padding: 16px;
  font-size: 14px;
  display: flex;
  svg {
    fill: #5183cf;
    flex-shrink: 0;
    margin-right: 6px;
  }
}

.col-12,
.col-lg-6 {
  padding: 0 10px;
}

img.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: 0 auto !important;
}

.screen__opacity {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

@media screen and (max-width: 991px) {
  .space-for-sm {
    padding-top: 20px;
  }
}

.banner-wrapper {
  position: relative;
  max-width: 530px;
  margin: 0 auto;
  @media screen and (max-width: 990px) {
    margin-bottom: 40px;
    margin-top: 20px;
  }
}
img.banner {
  width: 100%;
  height: auto;
}
.preview {
  position: absolute;
  z-index: 3;
  max-width: 60%;
  height: auto;
  margin: initial;
  left: initial;
  right: initial;
  padding: 0;

  &.ripple,
  &.static.display-temp {
    max-width: 30%;
  }
  &.twist.notification-temp,
  &.pure.white-temp,
  &.float.motion-temp {
    margin-right: -15px;
  }
  &.center {
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
  }
  &.top-left {
    left: 15px;
    top: 50px;
  }
  &.top-right {
    top: 50px;
    right: 15px;
  }
  &.bottom-left {
    bottom: 10px;
    left: 15px;
  }
  &.bottom-right {
    bottom: 10px;
    right: 15px;
  }
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
