<template>
  <div class="form-item">
    <label class="form-item__label">
      {{ $t(titlePath) }}
    </label>
    <div class="checkbox-wrapper">
      <label class="checkbox" v-for="(el, key) in list" :key="key">
        <input
          type="checkbox"
          :value="el"
          @change="setValue"
          :checked="el === value"
        />
        <span class="checkbox__visible"></span>

        <span class="checkbox__text">
          {{ $t(`${path}${el}`) }}
        </span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckboxList",
  props: {
    value: {
      type: String,
      required: true,
    },
    titlePath: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setValue(event) {
      this.$emit("input", event.target.value);
      if (event.target.value === this.value) {
        event.target.checked = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
}
</style>