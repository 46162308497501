export default {
  methods: {
    isValidSelector(selector) {
      try {
        document.querySelector(selector);
        return true;
      } catch (e) {
        if (e instanceof DOMException && e.code === DOMException.SYNTAX_ERR) {
          return false;
        }
        throw e; // If it's not a SyntaxError, rethrow the exception as it might be something else we're not handling.
      }
    },
  },
};
