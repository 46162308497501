<template>
  <div class="col-12 col-xl-6">
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.pop_type") }}

            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.description.onclick.pop_type'),
                triggers: ['hover', 'click'],
              }"
              symbol="info"
              size="0 0 18 18"
              role="info"
            ></svg>
          </label>
          <custom-multiselect v-model="pop_type" :list="pop_type_options" class="multiselect-wrapper form-item__field" :allowEmpty="false">
            <template v-slot:block="props">
              <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
            </template>
            <template v-slot:list="props">
              <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
            </template>
          </custom-multiselect>
          <div class="warn-block mt-2" v-if="pop_type.title === 'new_tab_left_with_redirect'">
            <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
            <p>
              {{ $t("platform.settings.pop.redirect_warn") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label info-popup">
            {{ $t("platform.settings.pop.selector") }}

            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.description.onclick.selector'),
                triggers: ['hover', 'click'],
              }"
              symbol="info"
              size="0 0 18 18"
              role="info"
            ></svg>
          </div>
          <div class="form-item__field">
            <input type="text" :class="{ invalid: !$v.selector.valid }" v-model.trim="selector" />
            <small v-if="!$v.selector.valid" class="error mt-2">
              {{ $t("platform.settings.pop.invalid_selector") }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 form-input-wrapper">
        <Counter v-model="display_frequency" :max="10" :min="1" :title="'platform.settings.pop.display_frequency'" :description="'platform.settings.description.onclick.display_frequency'" />
      </div>
      <div class="col-12 col-sm-6 col-md-4 form-input-wrapper">
        <Counter v-model="period" :max="period_time_unit && period_time_unit.id === 3 ? 48 : 60" :min="0" :title="'platform.settings.pop.period'" :description="'platform.settings.description.onclick.period'" />
      </div>
      <div class="col-12 col-sm-12 col-md-4 form-input-wrapper">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.time_unit") }}

            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.description.onclick.time_units_period'),
                triggers: ['hover', 'click'],
              }"
              symbol="info"
              size="0 0 18 18"
              role="info"
            ></svg>
          </label>
          <custom-multiselect v-model="period_time_unit" :list="period_unit_options" class="multiselect-wrapper multiselect-w-100 form-item__field" :allowEmpty="false">
            <template v-slot:block="props">
              <span>{{ $t(`platform.settings.pop.time_units.${props.value.title}`) }}</span>
            </template>
            <template v-slot:list="props">
              <span>{{ $t(`platform.settings.pop.time_units.${props.value.title}`) }}</span>
            </template>
          </custom-multiselect>
        </div>
      </div>
      <div class="col-12 mb-4 pt-4">
        <h3>{{ $t("platform.settings.pop.click_condition") }}</h3>
      </div>
      <div class="col-12 col-md-4">
        <Counter v-model="interval" :max="60" :min="0" :title="'platform.settings.pop.interval'" :description="'platform.settings.description.onclick.interval'" />
      </div>
      <div class="col-12 col-md-4">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.time_unit") }}

            <svg
              v-svg
              v-tooltip="{
                content: $t('platform.settings.description.onclick.time_units_interval'),
                triggers: ['hover', 'click'],
              }"
              symbol="info"
              size="0 0 18 18"
              role="info"
            ></svg>
          </label>
          <custom-multiselect v-model="interval_time_unit" :list="interval_unit_options" class="multiselect-wrapper multiselect-w-100 form-item__field" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ $t(`platform.settings.pop.time_units.${props.value.title}`) }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ $t(`platform.settings.pop.time_units.${props.value.title}`) }}
              </span>
            </template>
          </custom-multiselect>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="form-item">
          <label class="form-item__label">
            {{ $t("platform.settings.pop.min_clicks_title") }}
          </label>
          <custom-multiselect v-model="min_clicks" :list="min_clicks_list" class="multiselect-wrapper multiselect-w-100 form-item__field" :allowEmpty="false">
            <template v-slot:block="props">
              <span>{{ $formatLabel(props.value.title) }}</span>
            </template>
            <template v-slot:list="props">
              <span>{{ $formatLabel(props.value.title) }}</span>
            </template>
          </custom-multiselect>
        </div>
        <DisplaySettings v-if="featureFlags.includes('enable-display-settings')" :displaySettings="displaySettings" :disableOsVersions="osVersions" onclickOnly />
      </div>

      <div class="col-12">
        <button :disabled="!$v.selector.valid" @click.prevent="sendRequest" class="btn mb-2 _mob100">
          {{ $t("save") }}
        </button>
        <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green mb-2 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="coolicon"></svg> {{ $t("platforms.table.get_code") }}</button>
      </div>
    </div>
    <alert v-if="$alert.title" />
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>
<script>
import Counter from "../Counter.vue";
import DisplaySettings from "../DisplaySettings.vue";
import siteSettingsService from "@/api/siteSettingsService";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";
import isValidSelector from "@/mixins/isValidSelector";

export default {
  name: "OnClick",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    Counter,
    DisplaySettings,
  },
  mixins: [validationMixin, platformSettingsGetCode, isValidSelector],
  data() {
    return {
      display_frequency: 1,
      selector: "",
      pop_type: { id: 1, title: "new_tab" },
      pop_type_options: [
        { id: 1, title: "new_tab" },
        { id: 2, title: "new_tab_left" },
        { id: 3, title: "new_tab_left_with_redirect" },
        { id: 4, title: "replace" },
        { id: 5, title: "popup" },
      ],
      interval: 0,
      interval_time_unit: { id: 1, title: "seconds", value: 1 },
      interval_unit_options: [
        { id: 1, title: "seconds", value: 1 },
        { id: 2, title: "minutes", value: 60 },
      ],
      period: 0,
      period_time_unit: { id: 1, title: "seconds", value: 1 },
      period_unit_options: [
        { id: 1, title: "seconds", value: 1 },
        { id: 2, title: "minutes", value: 60 },
        { id: 3, title: "hours", value: 3600 },
      ],
      min_clicks: { title: "platform.settings.pop.min_clicks.one", value: 1 },
      min_clicks_list: [
        { title: "platform.settings.pop.min_clicks.one", value: 1 },
        { title: "platform.settings.pop.min_clicks.two", value: 2 },
        { title: "platform.settings.pop.min_clicks.three", value: 3 },
        { title: "platform.settings.pop.min_clicks.four", value: 4 },
        { title: "platform.settings.pop.min_clicks.five", value: 5 },
        { title: "platform.settings.pop.min_clicks.six", value: 6 },
        { title: "platform.settings.pop.min_clicks.seven", value: 7 },
        { title: "platform.settings.pop.min_clicks.eight", value: 8 },
        { title: "platform.settings.pop.min_clicks.nine", value: 9 },
        { title: "platform.settings.pop.min_clicks.ten", value: 10 },
      ],
      displaySettings: {
        disableDeviceSell: "",
        disableOsSell: "",
        notShowReff: false,
        disableYandex: false,
        linkOnly: false,
      },
      osVersions: [],
    };
  },
  validations: {
    selector: {
      valid: function (value) {
        // return /^[A-Za-z0-9_\-.]+$/.test(value); - if want to add "-" and "_" and "."
        return this.isValidSelector(value) || value === "";
      },
    },
  },
  watch: {
    period_time_unit() {
      if (this.period_time_unit.id === 3) {
        if (this.period > 48) {
          this.period = 48;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
  },
  mounted() {
    siteSettingsService
      .getOsVersions()
      .then((res) => {
        if (res && res.status === 200) {
          this.osVersions = res.data;
          return siteSettingsService.getOnClick(this.$route.params.id);
        }
      })
      .then((resp) => {
        if (resp && resp.status === 200) {
          this.selector = resp.data.selector;
          this.pop_type = this.setPopType(resp.data.type);
          this.min_clicks = this.min_clicks_list.filter((el) => el.value === resp.data.minClicks)[0] || { title: "platform.settings.pop.min_clicks.one", value: 1 };
          this.display_frequency = resp.data.capping.limit;
          this.interval_time_unit = this.interval_unit_options.find((el) => el.value === resp.data.delayUnit) || { id: 2, title: "seconds", value: 1 };
          this.interval = resp.data.delay / resp.data.delayUnit || 0;
          this.period_time_unit = this.period_unit_options.find((el) => el.value === resp.data.capping.durationUnit) || { id: 2, title: "seconds", value: 1 };
          this.period = resp.data.capping.duration / resp.data.capping.durationUnit || 0;

          this.displaySettings = {
            disableDeviceSell: resp.data.display_settings?.disable_devices || [],
            disableOsSell: this.osVersions.filter((el) => resp.data.display_settings?.disable_os.includes(el.value)) || [],

            notShowReff: resp.data.display_settings?.disable_no_referer || false,
            disableYandex: resp.data.display_settings?.disable_yandex_browser || false,
          };
          this.displaySettings.linkOnly = resp.data?.click_on_links_only || false;
        }
      });
  },
  methods: {
    sendRequest() {
      if (!this.$v.selector.valid) return;

      const payload = {
        min_clicks: this.min_clicks.value,
        enable: true,
        id: this.$route.params.id,
        open_type: this.pop_type.title,
        frequency_limit: this.display_frequency,
        selector: this.selector,
        period: this.period,
        unit_of_time: this.period_time_unit.value,
        delay_interval: this.interval,
        unit_of_delay: this.interval_time_unit.value,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell || [],
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,

        click_on_links_only: this.displaySettings.linkOnly,
      };

      siteSettingsService.setOnClick(payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
    setPopType(type) {
      const defaultOption = { id: 1, title: "new_tab" };
      const selectedOption = this.pop_type_options.find((option) => option.title === type);
      return selectedOption || defaultOption;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.warn-block {
  display: flex;
  align-items: start;
  column-gap: 6px;
  p {
    margin: 0;
    font-size: 14px;
    color: #626262;
  }
  svg {
    width: 40px;
    fill: #626262;
  }
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

.form-input-wrapper {
  .form-item {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
