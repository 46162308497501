<template>
  <div>
    <div class="modal-content">
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true"
          ><svg v-svg symbol="x" size="0 0 17 17"
        /></span>
      </button>
      <div class="modal-body">
        <div class="title-h2 pb-2">
          {{ $t(`platform.settings.click_push.drag_and_drop.modal_title`) }}
        </div>
        <div class="mt-3">
          <div class="cut">
            <vue-cropper
              ref="cropper"
              :src="img"
              :aspectRatio="fixedNumber"
              :minContainerHeight="450"
              :minContainerWidth="450"
              :autoCropArea="0.8"
            />
          </div>
        </div>
        <button
          type="button"
          class="btn _mob100 _bg-green mt-3"
          @click="setImage"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "modal-cropper",
  components: {
    VueCropper,
  },
  props: {
    img: {
      type: String,
      default: "",
    },
    square: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    autoCropWidth: {
      type: Number,
      default: 192,
    },
    autoCropHeight: {
      type: Number,
      default: 192,
    },
  },
  data() {
    return {
      crap: false,
      show: true,
    };
  },
  computed: {
    fixedNumber() {
      return this.square ? 1 / 1 : 492 / 328;
    },
  },
  methods: {
    setImage() {
      let model = {
        img: null,
        base64: null,
      };
      if (!this.$refs.cropper) return;
      model.img = this.$refs.cropper
        .getCroppedCanvas({
          width: this.autoCropWidth,
          height: this.autoCropHeight,
        })
        .toDataURL("image/png");
      model.base64 = model.img;
      this.$emit("cropped", model);
    },
  },
};
</script>
<style lang="scss" scoped>
.cut {
  &::v-deep {
    .cropper-line {
      background-color: #47b2ae;
    }
    .cropper-point.point-se::before {
      background-color: #47b2ae;
    }
    .cropper-point {
      background-color: #47b2ae;
    }
    .cropper-view-box {
      outline: 1px solid #47b2ae;
    }
  }
}
</style>