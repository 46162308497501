<template>
  <div class="warn-block mt-2">
    <svg v-if="showIcon" class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
    <p>
      <slot></slot>
    </p>
  </div>
</template>
<script>
export default {
  name: "InformationBlock",
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.warn-block {
  display: flex;
  align-items: start;
  column-gap: 6px;
  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    color: #868c9d;
  }
  svg {
    width: 40px;
    fill: #868c9d;
  }

  &.error {
    p {
      color: #c24848;
      font-weight: 500;
    }
    svg {
      fill: #c24848;
    }
  }
}
</style>
