<template>
  <div class="form-item pb-2">
    <label class="form-item__label"
      >{{ $t(`platform.settings.click_push.request_types.title`) }}
    </label>
    <div class="form-item__field request-type-wrapper">
      <div
        class="request-type__el"
        v-for="(el, key) in types"
        :key="key"
        :class="{ active: value === el }"
      >
        <button @click="$emit('input', el)">
          <img
            :src="
              require(`@/assets/images/home/platformSettings/${el}${
                value === el ? '-active' : ''
              }.svg`)
            "
            alt="choose request type"
          />
        </button>
        <span class="request-name">
          {{ $t(`platform.settings.click_push.request_types.${el}`) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RequestType",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      types: ["standard", "lightbox", "panel", "safari", "button"],
    };
  },
};
</script>
<style lang="scss" scoped>
.request-type-wrapper {
  display: flex;
  gap: 16px 7px;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: space-evenly;
  }

  .request-type__el {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px 0;

    .request-name {
      color: #8e8e95;
      font-size: 14px;
      line-height: 20px;
      transition: 0.3s all;
    }

    button {
      border-radius: 10px;
      background: transparent;
      border: 1px solid transparent;
      transition: 0.3s all ease;

      &:focus {
        outline: none;
      }
    }

    &.active {
      .request-name {
        color: #37807d;
        font-weight: 600;
      }

      button {
        border: 1px solid #37807d;
      }
    }
  }
}
</style>