<template>
  <div
    class="form-item fields-section drag-and-drop"
    :class="{ 'active-drag': activeDragZone }"
    @dragleave="activeDragZone = false"
    @drop="setImg"
    @dragover="dragOver"
  >
    <h3 class="drag_title">
      {{ $t(`platform.settings.click_push.drag_and_drop.title`) }}
    </h3>
    <div class="drag-wrapper">
      <img
        class="drag-image"
        :src="
          value
            ? value
            : require(`@/assets/images/home/platformSettings/defaultIcon.svg`)
        "
        :width="108"
        :height="108"
        alt="default icon"
      />
      <div>
        <label for="click-push-image" class="btn _border w-100 drag-button">
          <svg v-svg symbol="img" size="0 0 17 17" role="info" />
          {{ $t(`platform.settings.click_push.drag_and_drop.button_text`) }}
        </label>
        <input
          id="click-push-image"
          ref="image"
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          @change="uploadImg($event)"
        />
        <InformationBlock>
          {{ $t(`platform.settings.click_push.drag_and_drop.info_text`) }}
        </InformationBlock>
      </div>
    </div>
    <modal
      :width="600"
      :name="`cropModal`"
      height="auto"
      :scrollable="true"
      @closed="closedOpenCrop"
    >
      <CropImgModal
        :autoCropWidth="192"
        :autoCropHeight="192"
        @close="closeModal"
        :img="image"
        square
        fixed
        @cropped="cropped($event)"
      />
    </modal>
  </div>
</template>
<script>
import InformationBlock from "@/components/InformationBlock.vue";
import CropImgModal from "../modal/CropImgModal.vue";
export default {
  name: "DragAndDrop",
  components: { InformationBlock, CropImgModal },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      activeDragZone: false,
    };
  },
  methods: {
    uploadImg(e) {
      const file = e.target.files[0];
      if (!/\.(|jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.image = data;
        this.image && this.$modal.show("cropModal");
      };
      reader.readAsArrayBuffer(file);
    },
    setImg(event) {
      this.activeDragZone = false;
      event.preventDefault();
      const file = [...event.dataTransfer.files][0];
      if (!/\.(|jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([event.target.result]));
        } else {
          data = e.target.result;
        }
        this.image = data;
        this.image && this.$modal.show("cropModal");
      };
      reader.readAsDataURL(file);
    },
    dragOver(event) {
      event.preventDefault();
      this.activeDragZone = true;
    },
    cropped(event) {
      this.$emit("input", event.base64);
      this.$modal.hide("cropModal");
    },
    closeModal() {
      this.$modal.hide("cropModal");
    },
    closedOpenCrop() {
      this.$modal.hide("cropModal");
      this.image = null;
      this.$refs.image.value = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-and-drop {
  padding: 33px 67px 53px 48px;

  &.active-drag {
    background-color: #f5f9f9;
  }

  input[type="file"] {
    display: none;
  }

  .drag_title {
    margin-bottom: 26px;
  }

  .drag-image {
    margin-right: 34px;
    border-radius: 10px;
  }

  .drag-button {
    background: white;
    display: flex;
    gap: 0 10px;
    align-items: center;
    margin-bottom: 20px;
  }

  .drag-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px 0;
  }
}
</style>