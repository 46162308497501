<template>
  <div class="preview-2click">
    <div class="preview-2click-wrapper">
      <div class="position-relative h-100 w-100" v-if="!loading">
        <div
          v-if="!(button_size === 'large' && request_type === 'button')"
          class="request-notif"
          :class="[request_type, request_location]"
          :style="{ background: bgColor }"
        >
          <div
            class="exit"
            v-if="
              request_type === 'standard' ||
              request_type === 'panel' ||
              request_type === 'lightbox'
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <path
                d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L2.58579 4L0.292893 6.29289C-0.0976311 6.68342 -0.0976311 7.31658 0.292893 7.70711C0.683417 8.09763 1.31658 8.09763 1.70711 7.70711L4 5.41421L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L5.41421 4L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L4 2.58579L1.70711 0.292893Z"
                fill="#5D5D5D"
              />
            </svg>
          </div>
          <div
            :style="{ borderTop: `18px solid ${bgColor}` }"
            v-if="request_type === 'standard'"
            class="arrow"
          ></div>
          <div
            :style="{ borderTop: `18px solid ${bgColor}` }"
            v-if="request_type === 'button'"
            class="arrow-down"
          ></div>
          <img
            :src="
              img
                ? img
                : `@/assets/images/home/platformSettings/defaultIcon.svg`
            "
            v-if="request_type !== 'button'"
          />
          <span
            v-if="request_type !== 'standard' && request_type !== 'panel'"
            class="request-title"
            :style="{ color: textColor }"
            >{{ request_title || "" }}</span
          >
          <span class="request-text" :style="{ color: textColor }">
            {{ request_text || "" }}</span
          >
          <div class="actions">
            <div class="reject-btn" :style="{ color: buttonColor }">
              {{ reject_button_text || "" }}
            </div>
            <div class="accept-btn" :style="{ backgroundColor: buttonColor }">
              {{ apply_button_text || "" }}
            </div>
          </div>
        </div>
        <template v-if="request_type === 'button' && button_size !== 'large'">
          <svg
            class="bell-icon"
            :class="request_location"
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" :fill="button_color" />
            <path
              d="M29 21.7469V26.4133C29 26.636 29.0883 26.8497 29.2456 27.0074L30.7929 28.5591C30.9255 28.6921 31 28.8723 31 29.0601C31 29.452 30.6823 29.7697 30.2905 29.7697H15.7095C15.3177 29.7697 15 29.452 15 29.0601C15 28.8723 15.0745 28.6921 15.2071 28.5591L16.7544 27.0074C16.9117 26.8497 17 26.636 17 26.4133V21.7469C17 18.6581 18.63 16.0908 21.5 15.4089V14.7269C21.5 13.8946 22.17 13.2227 23 13.2227C23.83 13.2227 24.5 13.8946 24.5 14.7269V15.4089C27.36 16.0908 29 18.6681 29 21.7469Z"
              :fill="icon_color"
            />
            <path
              d="M25 30.7725C25 31.8756 24.1 32.7782 23 32.7782C21.89 32.7782 21 31.8756 21 30.7725H25Z"
              :fill="icon_color"
            />
          </svg>
        </template>
        <template v-if="request_type === 'button' && button_size === 'large'">
          <div
            class="button-layout"
            :class="request_location"
            :style="{ backgroundColor: button_color }"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29 21.7469V26.4133C29 26.636 29.0883 26.8497 29.2456 27.0074L30.7929 28.5591C30.9255 28.6921 31 28.8723 31 29.0601C31 29.452 30.6823 29.7697 30.2905 29.7697H15.7095C15.3177 29.7697 15 29.452 15 29.0601C15 28.8723 15.0745 28.6921 15.2071 28.5591L16.7544 27.0074C16.9117 26.8497 17 26.636 17 26.4133V21.7469C17 18.6581 18.63 16.0908 21.5 15.4089V14.7269C21.5 13.8946 22.17 13.2227 23 13.2227C23.83 13.2227 24.5 13.8946 24.5 14.7269V15.4089C27.36 16.0908 29 18.6681 29 21.7469Z"
                :fill="text_color"
              />
              <path
                d="M25 30.7725C25 31.8756 24.1 32.7782 23 32.7782C21.89 32.7782 21 31.8756 21 30.7725H25Z"
                :fill="text_color"
              />
            </svg>
            <span :style="{ color: text_color }">{{ request_title }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    request_type: {
      type: String,
      default: "standard",
    },
    button_size: String,
    request_title: String,
    request_text: String,
    icon_color: String,
    bg_color: {
      type: String,
      default: "#FFFFFF",
    },
    button_color: {
      this: String,
      default: "#37807D",
    },
    text_color: {
      type: String,
      default: "#000000",
    },
    apply_button_text: String,
    reject_button_text: String,
    request_location: {
      type: String,
      default: "left",
    },
    img: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      bgColor: this.bg_color,
      textColor: this.text_color,
      buttonColor: this.button_color,
    };
  },
  watch: {
    bg_color(val) {
      this.bgColor = val;
    },
    text_color(val) {
      this.textColor = val;
    },
    button_color(val) {
      this.buttonColor = val;
    },
    button_size(val) {
      if (val === "small" && this.request_type === "button") {
        this.textColor = "";
      }
    },
    request_type(val) {
      if (val === "safari") {
        this.bgColor = "";
        this.textColor = "";
        this.buttonColor = "#444444";
      } else if (val === "button") {
        if (this.button_size === "small") {
          this.textColor = "";
        } else {
          this.textColor = this.text_color;
        }
        this.buttonColor = this.button_color;
        this.bgColor = this.bg_color;
      } else {
        this.bgColor = this.bg_color;
        this.textColor = this.text_color;
        this.buttonColor = this.button_color;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.preview-2click {
  height: 400px;
  max-width: 570px;
  background-image: url(~@/assets/images/home/platformSettings/preview-click.svg);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px;
  position: sticky;
  top: 20px;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    max-width: none;
    height: 0;
    width: 100%;
    padding-top: 68%;
  }

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .position-relative {
    padding: 20px;
  }
}
.exit {
  position: absolute;
  right: 12px;
  top: 6px;
}
.request-notif {
  position: relative;
  top: 44px;
  left: 0;
  height: 140px;
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 20px;
  font-size: 15px;
  border-radius: 10px;
  filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.07));

  &.standard {
    width: 386px;
    height: 147px;
    animation: slide-top 0.3s forwards;
    .arrow {
      position: absolute;
      width: 0;
      left: 23px;
      top: -5px;
      height: 0;
      transform: rotate(60deg);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 18px solid #fff;
    }

    img {
      max-width: 55px;
      max-height: 55px;
      border-radius: 6px;
    }

    span.request-text {
      position: absolute;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      padding-right: 20px;
      -webkit-box-orient: vertical;
      left: 88px;
      top: 26px;
    }
    .actions {
      display: flex;
      align-items: center;
      margin-top: 8px;
      gap: 16px;
      justify-content: center;
    }
    .reject-btn {
      color: #37807d;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 50%;
    }
    .accept-btn {
      min-height: 35px;
      background: #37807d;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 50%;
      color: #fff;
      padding: 8px 16px;
      border-radius: 5px;
    }
    &.center {
      right: 0;
      margin: 0 auto;
    }
    &.right {
      background: rgb(255, 255, 255);
      right: 10px;
      left: auto;
      position: absolute;
      top: 64px;
    }
  }

  &.lightbox {
    animation: slide-left 0.3s forwards;
    width: 328px;
    height: 291px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 46px;

    @media (max-width: 1350px) and (min-width: 1201px) {
      top: calc(100% - 290px);
    }
    img {
      margin: 10px auto;
      display: block;
      border-radius: 6px;
      max-width: 55px;
      max-height: 55px;
    }
    .request-title {
      margin-top: 20px;
      font-weight: bold;
      font-size: 16px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
    }
    .request-text {
      line-height: 1.6;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .reject-btn,
    .accept-btn {
      width: 110px;
      display: inline-block;
      margin-top: 30px;
    }
    .accept-btn {
      background: #37807d;
      color: #fff;
      border-radius: 5px;
      padding: 8px 16px;
    }
    .reject-btn {
      margin: 8px;
    }
  }
  &.panel {
    animation: slide-top 0.3s forwards;
    top: 44px;
    left: 0px;
    height: 97px;
    width: 100%;
    max-width: 100%;
    padding: 9px 20px 15px;
    .request-text {
      font-size: 13px;
      padding-left: 10px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 92%;

      white-space: nowrap;
    }
    img {
      border-radius: 6px;
      height: 26px;
      width: 26px;
    }
    .actions {
      display: flex;
      column-gap: 10px;
      justify-content: center;
    }
    .accept-btn,
    .reject-btn {
      width: auto;
      display: inline-block;
      margin-top: 10px;
    }
    .reject-btn {
      margin-top: 18px;
    }
    .accept-btn {
      background: #37807d;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 115px;
      color: #fff;
      padding: 8px 16px;
      border-radius: 5px;
    }
  }
  &.safari {
    animation: slide-left 0.3s forwards;
    border-radius: 0;
    margin: 0 auto;
    height: 138px;
    width: 386px;
    padding: 18px 21px;

    img {
      height: 55px;
      width: 55px;
    }

    .request-title {
      position: absolute;
      color: #444444;
      font-weight: 600;
      left: 100px;
      overflow: hidden;
      white-space: nowrap;
      max-width: 70%;
      text-overflow: ellipsis;
    }
    .request-text {
      position: absolute;
      left: 100px;
      top: 48px;
      color: #676868;
      line-height: 18px;
      padding-right: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .actions {
      display: flex;
      column-gap: 10px;
      justify-content: right;
      margin-top: 16px;
    }
    .accept-btn {
      padding: 6px;
      right: 14px;
      border-radius: 4px;
      border: 1px solid #5086d9;
      background: linear-gradient(180deg, #88baf9 0%, #5086d9 100%);
      color: #fff;
      font-weight: bold;
    }
    .reject-btn {
      padding: 6px;
      border-radius: 4px;
      border: 1px solid #aaa;
    }
  }
  &.button {
    opacity: 0;
    animation: show 0.3s forwards;
    max-width: 250px;
    bottom: 90px;
    top: auto;
    position: absolute;
    left: 20px;
    height: auto;
    &.right {
      right: 20px;
      left: auto;
      .arrow-down {
        right: 18px;
        left: auto;
      }
    }
    .arrow-down {
      position: absolute;
      width: 0;
      left: 18px;
      bottom: -16px;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 18px solid #fff;
    }
    .request-title {
      font-weight: bold;
      font-size: 15px;
      display: block;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
    }
    .request-text {
      font-size: 12px;
      color: #212121;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .actions {
      display: flex;
      column-gap: 10px;
      margin-top: 10px;
      justify-content: left;
      align-items: center;
      .reject-btn {
        color: #37807d;
        max-width: 115px;
      }
      .accept-btn {
        order: -1;
        background: #37807d;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 115px;
        color: #fff;
        padding: 8px 16px;
        border-radius: 5px;
      }
    }
  }
  &.small {
    display: none;
  }
}

.accept-btn,
.reject-btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
  cursor: pointer;
}

.button-layout {
  background: #37807d;
  border-radius: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 24px;
  left: 20px;
  width: 154px;
  padding: 5px 20px 5px 0;
  &.right {
    left: auto;
    right: 20px;
  }
  span {
    display: inline-block;
    width: 100px;
    color: #fff;
    font-size: 14px;
    line-height: 1.3;
  }
}

.bell-icon {
  position: absolute;
  left: 25px;
  bottom: 25px;
  &.right {
    right: 25px;
    left: auto;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-left {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>